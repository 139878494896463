import {Component, Inject, OnInit} from '@angular/core';
import {MessageType} from "./model/message-type";
import {MAT_DIALOG_DATA} from "@angular/material/dialog";

@Component({
  selector: 'app-show-message',
  templateUrl: './show-message.component.html',
  styleUrls: ['./show-message.component.scss']
})
export class ShowMessageComponent implements OnInit {

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: MessageType,
  ) {
  }

  ngOnInit(): void {
  }

}
