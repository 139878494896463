import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {ShowMessageComponent} from './show-message.component';
import {MatDialogModule} from "@angular/material/dialog";
import {MatButtonModule} from "@angular/material/button";


@NgModule({
  declarations: [
    ShowMessageComponent
  ],
  imports: [
    CommonModule,
    MatDialogModule,
    MatButtonModule,
  ]
})
export class ShowMessageModule {
}
