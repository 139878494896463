import {Router} from '@angular/router';
import Swal from 'sweetalert2';
import {TranslateService} from "@ngx-translate/core";
import {GridView} from "../model/grid";
import html2canvas from 'html2canvas';
import * as moment from "moment/moment";
import {environment} from "../../environments/environment";
import {FormControl, ɵValue} from "@angular/forms";

declare var $: any;
declare const window: any;

export class BaseComponent {
  language: string = '';
  // noPhotoPerson: string = 'assets/images/no-photo-profile.png';
  noPhotoPerson: string = 'https://www.wikihow.com/images/thumb/9/90/What_type_of_person_are_you_quiz_pic.png/1200px-What_type_of_person_are_you_quiz_pic.png';

  constructor(public router: Router,
              public translate: TranslateService) {
  }

  /*setCulture(culture: string) {
    localStorage.setItem('culture', culture);
  }*/


  set accessCulture(param: string) {
    localStorage.setItem('culture', param);
  }

  get accessCulture(): string {
    return localStorage.getItem('culture') ?? '';
  }

  set accessLanguage(param: string) {
    localStorage.setItem('language', param);
  }

  get accessLanguage(): string {
    return localStorage.getItem('language') ?? '';
  }

  handlerCultureLanguage(language: string) {
    this.language = language;
    this.accessCulture = language;
    this.accessLanguage = language;
    this.translate.use(language);
    console.log('language');
    console.log(language);
  }

  showMessage(t: string, m: string, type: any = 'warning') {
    Swal.fire({
      title: t,
      html: m,
      icon: type,
      confirmButtonColor: '#032E58',
    });
  }

  onError(error: any) {
    if (error.status === 401) {
      this.router.navigate(['/login']);
      return;
    } else {
      this.showMessage('Atenção!', error.message, 'warning');
      return;
    }
  }

  setToken(token: string) {
    localStorage.setItem('token', token);
  }


  confirmMessage(t: string, m: string, callback: any = null) {
    Swal.fire({
      title: t,
      text: m,
      icon: 'warning',
      showConfirmButton: true,
      showCancelButton: true,
      confirmButtonText: 'Sim',
      cancelButtonText: 'Não',
      confirmButtonColor: '#032E58',
      cancelButtonColor: '#dc3545'
    }).then((result) => {
      if (!this.isNullOrUndefined(result.value) && result.value === true) {
        if (callback != null) {
          callback();
        }
      }
    });
  }


  isNullOrUndefined(value: any, object = false) {
    if (object) {
      return typeof value === 'undefined' || value == null;
    }
    return typeof value === 'undefined' || value == null || value.length === 0;
  }

  toggleLoader(b: boolean): void {
    if (!b) {
      $('#loaderBox').stop().fadeOut();
    } else {
      $('#loaderBox').stop().fadeIn();
    }
  }

  toggleLoaderCustom(b: boolean): void {
    if (!b) {
      $('#loaderBox2').stop().fadeOut();
    } else {
      $('#loaderBox2').stop().fadeIn();
    }
  }

  getErrorMessage(s: string) {
    return s === 'required' ? 'Campo obrigatório' : 'Campo inválido';
  }

  getDateFormatString(p: string): string {
    if (p === 'pt-BR') {
      return 'DD/MM/YYYY';
    } else {
      return 'MM/DD/YYYY';
    }
    // return '';
  }

  getNameFile(name: string) {
    return name + '-' + moment().utc(true).format() + '.xlsx';
  }

  permissionPageAdmin(profile: string): boolean {
    return profile === 'admin';
  }

  dateFilterOnlyFuture = (d: Date | null): boolean => {
    const today = new Date();
    today.setHours(0, 0, 0, 0);
    return d !== null && d >= today;
  };

  accessParms(token: string) {
    localStorage.setItem('parms', token);
  }

  handlerTimeTotal(startIn: any, endIn: any): string {
    let startDate = new Date(startIn);
    let endDate = new Date(endIn);

    console.log('Start Date:', startDate);
    console.log('End Date:', endDate);

    let diffInMilliseconds = Math.abs(endDate.getTime() - startDate.getTime());
    console.log('Difference in milliseconds:', diffInMilliseconds);

    // Calcular a diferença em segundos
    let totalSeconds = Math.floor(diffInMilliseconds / 1000);
    console.log('Total seconds:', totalSeconds);

    // Calcular minutos e segundos
    let minutes = Math.floor(totalSeconds / 60);
    let seconds = totalSeconds % 60;

    console.log('Minutes:', minutes);
    console.log('Seconds:', seconds);

    // Format minutes and seconds with leading zeros if needed
    let formattedMinutes = minutes.toString().padStart(2, '0');
    let formattedSeconds = seconds.toString().padStart(2, '0');

    // Retornar a diferença formatada como "mm:ss"
    return `${formattedMinutes}:${formattedSeconds}`;
  }

  extractHrefValues(htmlString: string): string[] {
    const hrefValues = htmlString.match(/href="#([^"]+)"/g)?.map(href => href.replace('href="#', '').replace('"', '')) || [];
    return hrefValues;
  }

  encodeTag(description: string | null | undefined) {
    const openTag = environment.openTag;
    const closeTag = environment.closeTag;
    description = description?.replace(/</g, openTag);
    description = description?.replace(/>/g, closeTag);
    return description;
  }

}


